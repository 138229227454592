// De javascript van deze widget staat in app.js.
// Dit is zo omdat de javascript gedeeld wordt met de cookieMsg die op alle pagina's terugkomt.
$(function () {
    if (window && window.localStorage) {
        var stsAcceptCookies = window.localStorage.getItem('stsAcceptCookies') === 'true';
        if (window.localStorage.getItem('stsAcceptCookies') === null) {
            var language = sts.language ? sts.language.split('-')[0] : 'nl';
            $.ajax({
                url: '/cookieBlocks/' + sts.multisite + '/cookieMsg-' + language + '.htm',
                context: document.body,
                success: function(data) {
                    $('body').append(data);
                }
            });
        }
        
        toggleCookieClasses(stsAcceptCookies);
        toggleCookieBlocks(stsAcceptCookies);

        $('body').on('click', '.acceptCookies, .declineCookies', function(e) {
            e.preventDefault();
            stsAcceptCookies = $(this).hasClass('acceptCookies');
            window.localStorage.setItem('stsAcceptCookies', stsAcceptCookies);
            toggleCookieClasses(stsAcceptCookies);
            toggleCookieBlocks(stsAcceptCookies);
            $('#cookieMsg').hide();
            return false;
        });
    }
});

function toggleCookieClasses(stsAcceptCookies) {
    if (stsAcceptCookies) {
        $('a.declineCookies').each(function() {
            $(this).removeClass('active');
        });
        $('a.acceptCookies').each(function() {
            $(this).addClass('active');
        });
    } else {
        $('a.declineCookies').each(function() {
            $(this).addClass('active');
        });
        $('a.acceptCookies').each(function() {
            $(this).removeClass('active');
        });
    }
}

function toggleCookieBlocks(stsAcceptCookies) {
    if (stsAcceptCookies) {
        $('.cookieBlock').each(function() {
            var el = $(this);
            var content = $(this).attr('data-content');
            $.ajax({
                url: '/cookieBlocks/' + sts.multisite + '/cookieBlock.' + content,
                context: document.body,
                success: function(data) {
                    el.html(data);
                }
            });
        });
    } else {
        var language = sts.language ? sts.language.split('-')[0] : 'nl';
        $.ajax({
            url: '/cookieBlocks/' + sts.multisite + '/cookieBlock.disabled-' + language + '.htm',
            context: document.body,
            success: function(data) {
                $('.cookieBlock').each(function() {
                    var el = $(this);
                    el.html(data);
                });
            }
        });

    }
}